import React from "react"

import SEO from "../components/seo"
import BaseLayout from "../components/layout/baseLayout"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Hero from "../components/landing/Hero"
import Benefits from "../components/landing/Benefits"
import Dynamics from "../components/landing/Dynamics"
import Testimonial from "../components/landing/Testemonials"
import Partners from "../components/landing/Partners"
import ArticleBox from "../components/solution/ArticleBox"
import ContactBanner from "../components/contact/ContactBanner"

const IndexPage = () => {
  const { t } = useTranslation()
  return (
    <div>
      <SEO title="hae.sh" isHome />
      <BaseLayout isHome>
        <Hero />
        <Partners />
        <Testimonial />
        <Benefits />
        <Dynamics />
        <ContactBanner />
        <ArticleBox
          count={3}
          prefix={"blog-"}
          headline={t("Index-Articles-Headline")}
          archiveButtonName={t("Index-Articles-Button")}
          archiveLink={"/blog"}
          className='bg-slate-50'
        />
        <div className="mt-4" />
      </BaseLayout>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
