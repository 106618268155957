import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Trans } from "react-i18next"
import { sr, srConfig } from "../../util/sr"
import ContactForm from "./ContactForm"

interface IContactBannerProps {}

const ContactBanner = ({}: IContactBannerProps) => {
  return (
    <div>
        <div className="max-w-4xl px-4 py-8 mx-auto sm:px-6 lg:px-8 xl:px-0">
          <div className="mb-4 text-3xl font-extrabold text-center">
            <Trans>Home-Contact-Header</Trans>
          </div>
          <ContactForm />
        </div>
    </div>
  )
}

export default ContactBanner