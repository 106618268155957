import React, { useEffect, useRef } from "react"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import { HeroImage } from "../common/HeroImage"
import BackgroundSquare from "../common/BackgroundSquare"
import { sr, srConfig } from "../../util/sr"

export default function Hero() {
  const textContainer = useRef(null)
  const heroImageContainer = useRef(null)
  useEffect(() => {
    if (sr && textContainer.current && heroImageContainer.current) {
      sr.reveal(textContainer.current, srConfig())
      sr.reveal(heroImageContainer.current, srConfig(450))
    }
  }, [])
  return (
    <div className="relative pt-24 mb-12 -mt-24 overflow-visible lg:mb-36 bottom-angled-box">
      <div className="z-10 mx-6 xl:mx-auto max-w-7xl lg:pt-12">
        <div className="flex flex-col lg:flex-row">
          <div className="order-3 max-w-md pt-6 mx-auto text-center lg:text-left lg:order-1">
            <BackgroundSquare />
            <div ref={textContainer} className="load-hidden">
              <h1 className="font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-2 xl:text-6xl">
                <span className="block text-3xl font-bold text-primary">
                  DICE
                  <span className="">.</span>
                </span>
                <span className="block mt-2 text-4xl font-extrabold text-slate-800">
                  <Trans>Hero-Line</Trans>
                </span>
              </h1>

              <p className="mt-12 text-base text-black sm:mt-8">
                <Trans>Hero-Subtext</Trans>
              </p>
              <div className="mt-8">
                <Link to="/solution" className="btn-solid">
                  <Trans>Hero-CTA-Button</Trans>
                </Link>
              </div>
            </div>
          </div>
          <div
            ref={heroImageContainer}
            className="order-2 w-full pl-8 pr-0 mt-8 md:mt-20 md:pl-40 md:pr-28 lg:px-0 lg:mt-0 lg:ml-8 load-hidden"
          >
            <HeroImage />
          </div>
        </div>
      </div>
    </div>
  )
}
