import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader

const Testimonial = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const data = useStaticQuery(graphql`
    query {
      testimonials: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/testimonials/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              date
              name
              role
              texten
              textde
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
      }
    }
  `)
  const people = data.testimonials.edges
  const { name, role, image, textde, texten } = people[0].node
    .frontmatter as any

  return (
    <div className="bg-slate-50">
      <Carousel
        autoPlay
        infiniteLoop
        interval={12000}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        swipeable
        swipeScrollTolerance={20}
        preventMovementUntilSwipeScrollTolerance={true}
        renderIndicator={(onClick, isSelected) => (
          <div onClick={onClick} className="inline-flex p-3">
            <div
              className={`w-4 h-4 rounded-sm shadow-md text-black transition-all ${
                isSelected ? "bg-primary" : "bg-white border border-slate-300"
              } `}
            ></div>
          </div>
        )}
      >
        {people.map(
          ({
            node: {
              frontmatter: { name, role, image, textde, texten },
            },
          }) => (
            <div key={name} className="max-w-4xl py-16 mx-auto px-8">
              <div className="md:grid md:grid-cols-3 md:grid-rows-2 text-left place-items-center md:place-items-start">
                <div className="row-span-2 select-none">
                  <div className="relative mx-auto h-48 w-48">
                    <Img
                      className="rounded-lg h-full w-full self-center"
                      fluid={image.childImageSharp.fluid}
                      alt={name}
                    />
                    <div
                      className="absolute top-0 -right-14 text-[12rem] text-slate-800 font-extrabold"
                      style={{ lineHeight: "5rem" }}
                    >
                      “
                    </div>
                  </div>
                </div>
                <div className="mt-12 md:mt-auto md:ml-4 col-span-2 text-xl">
                  {language == "de" ? textde : texten}
                </div>
                <div className="mt-6 ml-4 col-span-2">
                  <p className="font-bold">{name}</p>
                  <p className="text-primary">{role}</p>
                </div>
              </div>
            </div>
          )
        )}
      </Carousel>
    </div>
  )
}

export default Testimonial
