import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Trans } from "react-i18next"
import { sr, srConfig } from "../../util/sr"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"

interface IDiceTechnologyProps {}

const Dynamics = ({}: IDiceTechnologyProps) => {
  const imageRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    if (sr && textRef.current) {
      sr.reveal(imageRef.current, srConfig(200))
      sr.reveal(textRef.current, srConfig(300))
    }
  }, [])

  return (
    <div className="bg-slate-50">
      <div className="grid max-w-4xl grid-cols-1 gap-12 px-4 py-12 mx-auto mb-6 sm:px-6 lg:px-8 xl:px-0 md:grid-cols-3">
        <div ref={imageRef} className="m-auto snap-center">
          <StaticImage
            src="./images/Market_dynamics.png"
            alt="Market Dynamics Illustration"
            className="mx-16 sm:mx-28 md:mx-0"
            placeholder="blurred"
            quality={90}
            backgroundColor="transparent"
          />
        </div>
        <div className="md:col-span-2">
          <div className="mb-6 text-3xl font-extrabold text-center md:text-left">
              <Trans>Dynamic-Header</Trans>
          </div>
          
          <div ref={textRef} className="order-1 text-left load-hidden">
            <p className="mt-4">
            <Trans>Dynamic-Text-Paragraph-1</Trans>
            </p>
            <p className="mt-4">
            <Trans>Dynamic-Text-Paragraph-2</Trans>
            </p>
            <p className="mt-4">
            <Trans>Dynamic-Text-Paragraph-3</Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dynamics