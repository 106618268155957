import React from "react"

interface IShiftedBoxIconProps {
  className?: string
  heightClass?: string // h-6
  widthClass?: string // w-6
  renderIcon: () => React.ReactNode
}

const ShiftedBoxIcon = ({
  className,
  heightClass = "h-24",
  widthClass = "w-24",
  renderIcon,
}: IShiftedBoxIconProps) => {
  return (
    <div
      className={`${
        className || ""
      } relative flex justify-center pointer-events-none select-none ${widthClass}`}
    >
      <div
        className={`absolute z-[1] top-0 left-0 rotate-primary bg-slate-200 rounded-md ${heightClass} ${widthClass} inline`}
      />
      <div
        className={`grid z-10 relative place-items-center ${heightClass} ${widthClass}`}
      >
        {renderIcon()}
      </div>
    </div>
  )
}

export default ShiftedBoxIcon
