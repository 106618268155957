import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import { Trans } from "gatsby-plugin-react-i18next"
import { sr, srConfig } from "../../util/sr"

const ImageItem = ({ item }) => (
  <>
    {!item?.img?.childImageSharp?.fluid ? (
      <img
        src={item.img.publicURL}
        alt={item.alt}
        className={item.classes || "h-20"}
      />
    ) : (
      <Img
        fluid={item.img.childImageSharp.fluid}
        className={(item.classes || "w-full") + " mx-auto"}
        alt={item.alt}
      />
    )}
  </>
)

const Partners = () => {
  const headlineRef = useRef(null)
  const logosRef = useRef(null)

  useEffect(() => {
    if (sr && headlineRef.current && logosRef.current) {
      sr.reveal(headlineRef.current, srConfig())
      sr.reveal(logosRef.current, srConfig(300))
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      sponsors: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/sponsors/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              alt
              href
              classes
              date
              img {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
      }
      partners: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/partners/" } }
        sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              alt
              href
              classes
              date
              img {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
      }
    }
  `)
  // const sponsorList = data.sponsors.edges
  // .sort(
  //   (item1, item2) =>
  //     item1?.node?.frontmatter?.date > item2?.node?.frontmatter?.date
  // )
  const partnerList = data.partners.edges

  return (
    <div className="">
      <div className="px-4 py-12 mx-auto max-w-8xl sm:px-6 lg:px-8 xl:px-0">
        <div ref={headlineRef} className="text-3xl font-extrabold text-center load-hidden">
          <Trans>Partner-Header</Trans>
        </div>
        <div
          ref={logosRef}
          className="grid grid-cols-2 pt-6 lg:pt-0 md:grid-cols-3 lg:grid-cols-6 load-hidden"
        >
          {partnerList.map((item, i) => (
            <div
              key={i}
              className="flex items-center w-full col-span-1 md:col-span-1 lg:col-span-1"
            >
              <a
                href={item.node.frontmatter.href}
                className="w-full px-6 py-6 sm:py-8 sm:px-12 md:px-14 md:py-6 lg:p-6 xl:p-10"
                target="_blank"
                rel="noopener norefferer"
              >
                <ImageItem item={item.node.frontmatter} />
              </a>
            </div>
          ))}
        </div>
        <div className="flex justify-center base-container">
          <Link to="/contact" className="mx-auto mt-2 mb-4 btn-outline">
            <Trans>Partner-Contact-CTA</Trans>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default Partners
