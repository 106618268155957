import { Link } from "gatsby"
import React, { useEffect, useRef } from "react"
import { Trans } from "react-i18next"
import { sr, srConfig } from "../../util/sr"
import ShiftedBoxIcon from "../common/ShiftedBoxIcox"

interface IBenefitsProps {}

const Benefits = ({}: IBenefitsProps) => {
  const companiesContainer = useRef(null)
  const auditorsContainer = useRef(null)
  const developersContainer = useRef(null)

  useEffect(() => {
    if (
      sr &&
      companiesContainer.current &&
      auditorsContainer.current &&
      developersContainer.current
    ) {
      sr.reveal(companiesContainer.current, srConfig(600))
      sr.reveal(auditorsContainer.current, srConfig(800))
      sr.reveal(developersContainer.current, srConfig(1000))
    }
  }, [])

  const items = [
    {
      ref: companiesContainer,
      headline: "Benefit-1-Headline",
      subheader: "Benefit-1-Subheader",
      description: "Benefit-1-Description",
      Icon: ({ className }) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="79.33"
          height="59.139"
          viewBox="0 0 79.33 59.139"
          className={className}
        >
          <path
            id="company"
            d="M34.156,19.694v4.891l8.085-4.16V14.893Zm0,35.134,8.085-4.16V43.688l-8.085,4.16ZM45.006,50.5,55.661,53.93V46.744L45.006,43.317Zm10.655,6.321L45.006,53.4v14H55.661Zm-13.42-3.056-8.085,4.16V67.4h8.085Zm-8.085-9.022,8.085-4.16v-6.98l-8.085,4.16Zm10.849-4.324,10.655,3.425V36.662L45.006,33.236ZM34.156,34.666l8.085-4.16V23.525l-8.085,4.161Zm10.849-4.324,10.655,3.425V26.581L45.006,23.155Zm0-10.081,10.655,3.425V18.531L45.006,14.586ZM66.784,43.387V67.4h7.037V45.7ZM64.02,67.4V43.615l-5.176,2.567V67.4ZM26.26,43.636V67.4h5.132V46.182ZM23.5,67.4V43.372L16.416,45.7V67.4ZM8.218,61.9v5.5h5.434V61.818l-3.117-.893ZM5.453,60.076,10.374,58l3.277.94V43.7l11.4-3.744L31.392,43.1V18.126l11.957-7.1,15.076,5.583v26.7l6.763-3.356,11.4,3.744V58.945L79.863,58l4.92,2.071V70.167H5.453ZM82.018,67.4V61.9L79.7,60.925l-3.118.893V67.4Z"
            transform="translate(-5.453 -11.028)"
            fill="#222b45"
          />
        </svg>
      ),
    },
    {
      ref: auditorsContainer,
      headline: "Benefit-2-Headline",
      subheader: "Benefit-2-Subheader",
      description: "Benefit-2-Description",
      Icon: ({ className }) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="53.415"
          height="64.295"
          viewBox="0 0 53.415 64.295"
          className={className}
        >
          <g id="auditor" transform="translate(0)">
            <path
              id="Pfad_782"
              data-name="Pfad 782"
              d="M20.266,28.65H12.155a1.446,1.446,0,0,0-1.53,1.53V52.217a1.446,1.446,0,0,0,1.53,1.53h8.111a1.446,1.446,0,0,0,1.53-1.53V30.18a1.534,1.534,0,0,0-1.53-1.53Zm-1.53,22.037h-5.05V31.71h5.05Z"
              transform="translate(-10.622 10.545)"
              fill="#222b45"
            />
            <path
              id="Pfad_783"
              data-name="Pfad 783"
              d="M27.041,23.348H18.93a1.446,1.446,0,0,0-1.53,1.53V57.934a1.446,1.446,0,0,0,1.53,1.53h8.111a1.446,1.446,0,0,0,1.53-1.53V24.878A1.534,1.534,0,0,0,27.041,23.348ZM25.51,56.4H20.46v-30h5.05Z"
              transform="translate(-3.317 4.828)"
              fill="#222b45"
            />
            <path
              id="Pfad_784"
              data-name="Pfad 784"
              d="M33.815,25.851H25.7a1.446,1.446,0,0,0-1.53,1.53v27.7a1.446,1.446,0,0,0,1.53,1.53h8.111a1.446,1.446,0,0,0,1.53-1.53v-27.7a1.646,1.646,0,0,0-1.53-1.53ZM32.285,53.7h-5.05V28.912h5.05Z"
              transform="translate(3.987 7.528)"
              fill="#222b45"
            />
            <path
              id="Pfad_785"
              data-name="Pfad 785"
              d="M40.59,21.949H32.479a1.446,1.446,0,0,0-1.53,1.53V59.443a1.446,1.446,0,0,0,1.53,1.53H40.59a1.446,1.446,0,0,0,1.53-1.53V23.479a1.534,1.534,0,0,0-1.53-1.53ZM39.06,57.912H34.01v-32.9h5.05Z"
              transform="translate(11.292 3.32)"
              fill="#222b45"
            />
            <path
              id="Pfad_786"
              data-name="Pfad 786"
              d="M28.38,22.814l13.008,9.641a1.646,1.646,0,0,0,2.143-.306L56.539,15.468l.306,4.132a1.461,1.461,0,0,0,1.683,1.377,1.382,1.382,0,0,0,1.377-1.683l-.765-8.111a1.461,1.461,0,0,0-1.683-1.377l-8.111.612a1.538,1.538,0,0,0,.306,3.061l4.591-.306L42,28.936,29.145,19.447a1.431,1.431,0,0,0-1.989.153L12.924,34.292a1.479,1.479,0,0,0,0,2.143h0a1.479,1.479,0,0,0,2.143,0Z"
              transform="translate(-8.636 -9.789)"
              fill="#222b45"
            />
          </g>
        </svg>
      ),
    }
  ]

  return (
    <div className="relative left-0 w-full px-8 pt-16 pb-24 lg:px-16">
      <div
        className="text-3xl font-extrabold text-center mb-14"
      >
        <Trans>Benefits-Header</Trans>
      </div>
      <div className="grid max-w-4xl grid-cols-1 mx-auto text-center md:grid-cols-2 gap-y-4 md:gap-x-14 lg:gap-x-24">
        {items.map(({ ref, headline, subheader, description, Icon }, i) => (
          <div ref={ref} key={i} className="mb-20 lg:mb-0">
            <ShiftedBoxIcon
              className="mx-auto"
              renderIcon={() => <Icon className="w-12 h-12 text-slate-800" />}
            />
            <div className="mt-10 mb-6 text-xl font-bold uppercase text-primary">
              <Trans>{headline}</Trans>
            </div>
            <div className="font-bold">
              <Trans>{subheader}</Trans>
            </div>
            <div className="">
              <Trans>{description}</Trans>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center base-container">
          <Link to="/solution" className="mx-auto mt-8 btn-outline">
            <Trans>KeyBenefits-Solution-CTA</Trans>
          </Link>
        </div>
    </div>
  )
}

export default Benefits
